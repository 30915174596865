import ProductTypesNew from '../components/digitalProductSale/productTypesNew';
import { LOGO_FULLMOVIL_WHITE } from '../lib/envariables';

function HeroHomeNew() {



  return (
    <section className="relative" >    

      <div className="max-w-full mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-20 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-6xl lg:text-9xl text-white font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Recarga</h1>
            <h2 className="bg-clip-text text-white text-center text-5xl lg:text-6xl">tu diversión</h2>
            <h3 className="bg-clip-text text-white text-center text-4xl">al alcance de un</h3>
            <h3 className="bg-clip-text text-white text-center text-3xl">con</h3>
            <div className="flex-shrink-0 text-center">
            
            
              <img
                className="h-20 w-30 my-0 mx-auto"
                src={LOGO_FULLMOVIL_WHITE}
                alt="home"
              />
           
            </div>
            <div className="max-w-full mx-auto">
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">

                <ProductTypesNew/>
                
              </div>
            </div>
          </div>

        

        </div>

      </div>
    </section>
  );
}

export default HeroHomeNew;