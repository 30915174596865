import axios from "axios";
import { API_WOMPI_HOST, API_WOMPI_HOST_TEST, PUBLIC_KEY, PUBLIC_KEY_TEST, PROD_MODE } from "../lib/envariables";

const WOMPI_ENVIROMENT = PROD_MODE.toUpperCase() == "TRUE" ? API_WOMPI_HOST : API_WOMPI_HOST_TEST;

const WOMPI_PUBLIC_KEY = PROD_MODE.toUpperCase() == "TRUE" ? PUBLIC_KEY : PUBLIC_KEY_TEST;

export const tgetTokenForCard = async (body) => {
  try {
    const resp = await axios.post(`${WOMPI_ENVIROMENT}/tokens/cards`, body, {
      headers: {
        Authorization: `Bearer ${WOMPI_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    console.error(error, error.stack);
    return error.response.data;
  }
};


export const tgetTokenForNequi = async (body) => {
  try {
    const resp = await axios.post(`${WOMPI_ENVIROMENT}/tokens/nequi`, body, {
      headers: {
        Authorization: `Bearer ${WOMPI_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    console.error(error, error.stack);
    return error.response.data;
  }
};

export const tgetStatusTokenForNequi = async (tokenNequi) => {
  try {
    const resp = await axios.get(`${WOMPI_ENVIROMENT}/tokens/nequi/${tokenNequi}`, {
      headers: {
        Authorization: `Bearer ${WOMPI_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    console.error(error, error.stack);
    return error.response.data;
  }
};

export const setSessionId = () =>  {
    window.$wompi.initialize(function (data, error) {
        if (error === null) {
          const sessionId = data.sessionId;
          sessionStorage.setItem('sessionId', sessionId);
          // `sessionId` es un string, por ejemplo: "1289_1696830983722-ab493d40c02e-278bab34-323va3"
        }
      });
}
