import React, { Fragment, useCallback, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Header from "../../partials/Header";
import "./styles.css";
import axios from "axios";
import { PAYMENTMANAGER_API_HOST } from "../../lib/envariables";

export const ResumenCompra = () => {
  
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [orderInfo, setOrderInfo] = useState({
          statusMessage: '',
          idPago: '',
          idProducto: '',
          proveedor: '',
          producto: '',
          telefono: '',
          email: '',
          metodoPago: '',
          valor: 0,
          status: 3
  });
  const [isLoading, setIsLoading] = useState(true);

  const requestTxId = searchParams.get('id');

  const requestTxPr = searchParams.get('pr');


  let idPago = "";
  let idProducto = "";
  let proveedor = "";
  let producto = "";
  let telefono = "";
  let email = "";
  let metodoPago = "";
  let valor = 0;
  let status = 3;
  let statusCode = false;
  let statusMessage = '';
  let errorMessage = '';


  // const getOrderData = async (requestTx) => {
    
  //   const order = await axios.get(`${PAYMENTMANAGER_API_HOST}/orders/v1/fullmovil-summary/${requestTx}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Cache-Control': 'no-cache',
  //     },
  //   });

  //   console.log(order.data.data)
  //   if (order.data.data) {
  //     setOrderInfo(order)
  //   }
    
    
  // };

  // console.log(orderInfo)
  // console.log(orderInfo.data.data)

  // useEffect(() => {
  //   const requestTx = searchParams.get('id');
  //   getOrderData(requestTx);
  // }, [searchParams]);

  const getOrderData = useCallback(async (requestTxId, requestTxPr) => {
      setTimeout(2000)

      let order;

      // Wompi

      if (requestTxId != null && requestTxPr == null) {

        order = await axios.get(`${PAYMENTMANAGER_API_HOST}/orders/v1/fullmovil-summary/${requestTxId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
          },
        });    

      }

      // Bepay

      if (requestTxPr != null && requestTxId == null) {

        order = await axios.get(`${PAYMENTMANAGER_API_HOST}/bporders/v1/fullmovil-summary-pr/${requestTxPr}`, {
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
          },
        });    

      }
       

      if (order) {
        setIsLoading(false);
        setOrderInfo(
          {
            statusMessage: order.data.data.summaryOrderData.transactionStatus,
            idPago: order.data.data.summaryOrderData.idPago,
            idProducto: order.data.data.summaryOrderData.referenciaProducto,
            proveedor: order.data.data.summaryOrderData.proveedor,
            producto: order.data.data.summaryOrderData.producto,
            telefono: order.data.data.summaryOrderData.telefono,
            email: order.data.data.summaryOrderData.email,
            metodoPago: order.data.data.summaryOrderData.metodoPago,
            valor: order.data.data.summaryOrderData.valor,
            status: order.data.data.summaryOrderData.codigo
          }
        )
      }
      
  }, [])

   useEffect(() => {
    getOrderData(requestTxId, requestTxPr);
  }, [getOrderData, requestTxId, requestTxPr]);

  // useEffect(() => {
  //   const requestTx = searchParams.get('id');
  //   // declare the async data fetching function
  //   const getOrderData = async () => {
  //     // get the data from the api
  //     const order = await axios.get(`${PAYMENTMANAGER_API_HOST}/orders/v1/fullmovil-summary/${requestTx}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Cache-Control': 'no-cache',
  //       },
  //     });

  
  //     // set state with the result
  //     setOrderInfo(order.data.data)
  //   }
  
  //   // call the function
  //   getOrderData()
  //     // make sure to catch any error
  //     .catch(console.error);
  // }, [searchParams])

if (orderInfo.statusMessage != '') {

   if (orderInfo.statusMessage == "DECLINED") {
    errorMessage = 'Tu pago no ha sido aprobado, por favor intenta de nuevo.';
    producto = orderInfo.producto;
    status = 2;
  } else if (orderInfo.statusMessage == "APPROVED") {
    errorMessage = '';
    statusMessage = orderInfo.transactionStatus;
    idPago = orderInfo.idPago;
    idProducto = orderInfo.idProducto;
    proveedor = orderInfo.proveedor;
    producto = orderInfo.producto;
    telefono = orderInfo.telefono;
    email = orderInfo.email;
    metodoPago = orderInfo.metodoPago;
    valor = orderInfo.valor;
    status = orderInfo.status;
    statusCode = true;
  } else {
    errorMessage = 'Tu pago no ha sido aprobado, por favor intenta de nuevo.';
    producto = orderInfo.producto;
    status = 2;
  } 

}
  
    

  const mainStyle = {
    height: "100%",
    background:
      "url(https://fullmovil.com.co/wp-content/uploads/2021/11/Full-movil-banner-home-3.png) no-repeat center center fixed",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    BackgroundSize: "cover",
  }; 

  const StatusIcon = () => {
    switch (status) {
      case 0:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fcb900"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-alert-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
        );
      case 1:
        return (
          <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto">
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            ></path>
          </svg>
        );
      case 200:
        return (
          <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto">
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            ></path>
          </svg>
        );
      case 2:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        );
  
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        {
          producto != 'Recarga saldo fullmovil' && (<Header />)
        }
        
        {/*  Page content */}
        <main className="flex-grow" style={mainStyle}>
          <div className="w-full pt-16 px-3 grid content-center">
            <div className="flex justify-center pt-4">
              <div className="max-w-lg shadow-lg bg-white rounded pb-4">
                <div className="bg-[#001174] text-white p-6">
                  <h2 className="m-0 font-bold text-2xl">Resumen del Pago</h2>
                </div>

                {
                  isLoading && (
                    <div className="flex justify-center">
                      <ReactLoading type="spin" color="gray" width={"8%"} className="mr-3" />
                      Obteniendo datos, espere un momento...
                    </div>
                  )
                }

                

                
                {
                  status == 1 || status == 200 ? (
                  <div className="grid md:grid-cols-2 items-center">
                    <div className="py-4 px-6">                    
                      <div className="text-gray-800 customFont">
                        Identificación de pago
                      </div>
                      <div className="customFont text-[#001174]">
                        {idPago}
                      </div>
                      <div className="text-gray-800 mt-4 customFont">
                        Orden No.
                      </div>
                      <div className="customFont text-[#001174]">
                        {idProducto}
                      </div>
                      {
                        status != 200 && (
                          <Fragment>
                          <div className="text-gray-800 mt-4 customFont">
                            Proveedor
                          </div>
                          <div className="customFont text-[#001174]">
                            {proveedor}
                          </div>
                        </Fragment>
                        )
                      }
                      
                      <div className="text-gray-800 mt-4 customFont">
                        Producto
                      </div>
                      <div className="customFont text-[#001174]">
                        {producto}
                      </div>
                      {
                        status != 200 && (
                          <Fragment>
                          <div className="text-gray-800 mt-4 customFont">
                            Número de celular
                          </div>
                          <div className="customFont text-[#001174]">
                            {telefono}
                          </div>
                        </Fragment>
                        )
                      }
                      
                      <div className="text-gray-800 mt-4 customFont">
                        Valor
                      </div>
                      <div className="customFont text-[#001174]">
                        ${new Intl.NumberFormat('es-CO').format(valor)}
                      </div>
                      <div className="text-gray-800 mt-4 customFont">Email</div>
                      <div className="customFont text-[#001174]">
                        {email}
                      </div>
                      <div className="text-gray-800 mt-4 customFont">
                        Método de pago
                      </div>
                      <div className="customFont text-[#001174]">
                        {metodoPago}
                      </div>

                      <div className="text-gray-800 mt-4 customFont">
                      {
                        producto != 'Recarga saldo fullmovil' && (         
                          <button
                            className="w-full max-w-lg text-center text-lg font-semibold bg-white text-[#001174] pt-3 pr-3 pb-3 pl-3
                            hover:bg-gray-100 rounded-md border"
                            onClick={() => {
                              window.print();
                            }}
                          >
                            Imprimir
                          </button>
                        )
                      }
                      </div>
                    </div>
                  <div>
                    <div className="flex justify-center">
                      <StatusIcon />
                    </div>
                    <h2 className="text-gray-800 customFont mt-3">
                      Estado del pago
                    </h2>
                    <div className="flex justify-center font-semibold text-2xl mb-4">
                      {status == 1 ? "Éxito" : statusMessage}
                    </div>
                  </div>
                  </div>
                  ) : (
                    <div className="grid md:grid-cols-1 items-center">
                  
                      <div className="py-4 px-6">
                        <div className="text-gray-800 customFont">
                          {errorMessage}
                        </div>
                        <div className="flex justify-center">
                        
                          <StatusIcon />
                        </div>
                      </div>
                    
                    </div>
                  )
                }         
                    
                
              </div>
            </div>

            <div className="flex justify-center mt-3 sticky bottom-0 mb-2">
                  {
                      producto == 'Recarga saldo fullmovil' ? (

                        <button
                          className="w-full max-w-lg text-center text-xl font-semibold bg-gray-800 text-white pt-3 pr-3 pb-3 pl-3
                          hover:bg-gray-600 rounded-md"
                          // href="fullmovil://inicio"
                          onClick={() => {
                            window.location.replace('android-app://fullmovil.page.link/Eit5');
                          }}
                        >
                          Regresar a Fullmovil
                        </button>

                      ) : (
                        <button
                          className="w-full max-w-lg text-center text-xl font-semibold bg-gray-800 text-white pt-3 pr-3 pb-3 pl-3
                          hover:bg-gray-600 rounded-md"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Volver al Inicio
                        </button>
                      )
                  }
              
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
