import cellphoneRecharge from "../icons/cellphoneRecharge.svg"
import entertaiment from "../icons/entertaiment.svg"
import bets from "../icons/bets.svg"
import games from "../icons/games.svg"
import services from "../icons/services.svg"

import bancoUnionLogoBlue from "../../images/banco_union/logo_banco_union_blue.png";
import bancoUnionLogoYellow from "../../images/banco_union/logo_banco_union_yellow.png";

export const types =

[
    {"code":"7", "description":"Recargas Celular","imageUrl":cellphoneRecharge},
    {"code":"5", "description":"Entretenimiento",imageUrl:entertaiment},
    {"code":"4", "description":"Juegos",imageUrl:games},
    {"code":"3", "description":"Servicios",imageUrl:services},
    {"code":"2", "description":"Apuestas",imageUrl:bets},
]

export const brandLayout =
{
  'banco-union': 
  {
    'primaryBg': 'bg-[#F8DF03]',
    'primaryBgColor': '#F8DF03',
    'brandLogo': bancoUnionLogoBlue,
    'brandLogo2': bancoUnionLogoYellow,
    'tratamientoDatosPersonales': 'https://kimmel.com.co/wp-content/uploads/2023/02/politica-tratamiento-datos-personales-2021.pdf',
    'brandLogoW': 'w-80',
    'brandLogoH': 'h-12',
    'contentBg': 'bg-[#FFFFFF]',
    'textColor': 'text-[#1581e0]',
    'textMobileMenuColor': 'text-[#606364]',
    'titleColor': 'text-[#FFFFFF]',
    'buttonBg': 'bg-[#F8DF03]',
    'buyButtonBg': 'bg-[#314D84]',
    'productName': 'text-[#314D84]',
    'buyButtonBgColor': '#314D84',
    'continueButtonText': 'text-[#314D84]',
    'continueButtonTextColor': '#314D84',
    'bodyBg': 'bg-[#36BDF5]',
    'bodyBgColor': '#36BDF5',
    'mobileMenuBg': 'bg-[#ffffff]',
    'productType': 10,
  },
  'axa-colpatria': 
  {
    'primaryBg': 'bg-[#ffffff]',
    'primaryBgColor': '#ffffff',
    'brandLogo': 'https://www.axacolpatria.co/image/layout_set_logo?img_id=44248865&t=1674767039389',
    'tratamientoDatosPersonales': 'https://kimmel.com.co/wp-content/uploads/2023/02/politica-tratamiento-datos-personales-2021.pdf',
    'brandLogoW': 'w-[105px]',
    'brandLogoH': 'h-[53px]',
    'contentBg': 'bg-[#FFFFFF]',
    'titleColor': 'text-[#FFFFFF]',
    'textColor': 'text-[#00008f]',
    'textMobileMenuColor': 'text-[#606364]',
    'buttonBg': 'bg-red-700',
    'buyButtonBg': 'bg-[#314D84]',
    'productName': 'text-[#314D84]',
    'buyButtonBgColor': '#314D84',
    'continueButtonText': 'text-[#314D84]',
    'continueButtonTextColor': '#314D84',
    'bodyBg': 'bg-[#FFFFFF]',
    'bodyBgColor': '#FFFFFF',
    'mobileMenuBg': 'bg-[#ffffff]',
    'productType': 10,
  }
}

