import React, { Fragment, useCallback, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../partials/Header";
import "./styles.css";
import axios from "axios";
import { PAYMENTMANAGER_API_HOST } from "../../lib/envariables";
import { tycLosOlivos } from "../../services/digitalProducts";

export const ActivacionLosOlivos = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [disabledButton, setDisabledButton] = useState(true);
  const [tycResponseText, setTycResponseText] = useState('');
  const [tycAcceptButtonActive, setTycAcceptButtonActive] = useState(true);
  const requestTx = searchParams.get('id'); 

  const handleAcceptTerms = async () => {
    const tycResult = await tycLosOlivos(requestTx);
    setTycResponseText(tycResult);
    if (tycResult) {
      setTycAcceptButtonActive(false);
    }
    
  }

  const handleOpenFile = () => {
    setDisabledButton(false);
  }

  const mainStyle = {
    height: "100%",
    background:
      "url(https://fullmovil.com.co/wp-content/uploads/2021/11/Full-movil-banner-home-3.png) no-repeat center center fixed",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    BackgroundSize: "cover",
  };



  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />
        {/*  Page content */}
        <main className="flex-grow" style={mainStyle}>
          <div className="w-full pt-16 px-3 grid content-center">
            <div className="flex justify-center pt-4">
              <div className="w-full md:w-1/2 shadow-lg bg-white rounded pb-4">
                <div className="bg-[#001174] text-white p-6">
                  <h2 className="m-0 font-bold text-2xl">Política de Tratamieto de Datos</h2>
                </div>
                <div className="grid md:grid-cols-1 items-center">
                  <div className="py-4">
                    <div className="text-gray-800 text-center">
                      
                      <a
                        className="w-full max-w-lg text-center text-xl underline font-semibold text-[#001174] pt-3 pr-3 pb-3 pl-3"
                        href={'https://cali.losolivos.co/wp-content/uploads/2018/10/SGI-SGC-PO-1-POLITICA-DE-PROTECCION-DE-DATOS-PERSONALES-V3.pdf'}
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleOpenFile}
                        >
                        Consultar Aquí
                      </a>

                      <p className="text-gray-600 mt-3 customFont">Después de leer la política, debes dar click en el botón <strong>Aceptar</strong>.</p>

                      <p className="text-[#001174] mt-3 customFont">
                        {
                          tycResponseText
                        }
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

           
            <div className="flex justify-center mt-3 sticky bottom-0 mb-2">

            {
              disabledButton ? (
                <button
                    className="w-full max-w-lg text-center text-xl font-semibold bg-gray-600 text-white pt-3 pr-3 pb-3 pl-3
                    hover:bg-gray-600 rounded-md"
                    disabled={true}
                    >
                    Aceptar
                  </button>
              ) : (

                
                  tycAcceptButtonActive ? (
  
                    <button
                      className="w-full max-w-lg text-center text-xl font-semibold bg-gray-800 text-white pt-3 pr-3 pb-3 pl-3
                      hover:bg-gray-600 rounded-md"
                      onClick={handleAcceptTerms}
                      >
                      Aceptar
                    </button>
                    
                  ) : (
                    <button
                      className="w-full max-w-lg text-center text-xl font-semibold bg-gray-800 text-white pt-3 pr-3 pb-3 pl-3
                      hover:bg-gray-600 rounded-md"
                      onClick={() => {
                        navigate("/");
                      }}
                      >
                      Volver a Fullmovil
                    </button>
                  )
                
                
              )
            }
              
              
            </div>
             

            
          </div>
        </main>
      </div>
    </>
  );
};
