import { useState, useEffect, useRef } from "react";
import Summary from "./summary";
import { useFormik } from "formik";
import { getPaymentMethods } from "../../services/digitalProducts";
import { generateDpoReference, generatePaymentReference } from "../../utils/generateReference";
import { PAYMENTMANAGER_API_HOST, PRODUCTION_ENV_URL, PROD_MODE, PUBLIC_KEY, PUBLIC_KEY_TEST, TEST_ENV_URL } from "../../lib/envariables";
import axios from "axios";

const CheckoutSeguros = ({ page, setPage, formData, setFormData }) => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [errorCretateOrder, setErrorCretateOrder] = useState(false);
  let [paymentMethodsReduce, setPaymentMethodsReduce] = useState(paymentMethods);
  const [productPrice, setProductPrice] = useState(formData.productValue != 0 ? formData.productValue : 1000);
  const inputReference = useRef(null);
  const options = ['Seleccione Método', 'Tarjeta', 'PSE', 'AirTm'];

  // Get references
  const paymentReference = generatePaymentReference();
  const dpoReference = generateDpoReference();
  let integritySign = "";

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values) => {
    const errors = {};

    if (values.productValue == '') {
      errors.productValue = "campo obligatorio";
    } else if (values.productValue < 1000) {
      errors.productValue = "Valor inválido";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "campo obligatorio";
    } else if (values.phoneNumber.length !== 10) {
      errors.phoneNumber = "Número de celular inválido";
    }

    // if (!values.payerPhone) {
    //   errors.payerPhone = "campo obligatorio";
    // } else if (values.payerPhone.length !== 10) {
    //   errors.payerPhone = "Número de celular inválido";
    // }

    if (!values.email) {
      errors.email = "campo obligatorio";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Dirección de email inválida";
    }

    if (!values.documentId) {
      errors.documentId = "campo obligatorio";
    } else if (
      !/^[0-9]{1,10}$/i.test(values.documentId)
    ) {
      errors.documentId = "Documento inválido";
    }

    // if (values.paymentMethod == 0) {
    //   errors.paymentMethod = "Seleccione método de pago";
    // }

    return errors;
  };

  // Pass the useFormik() hook initial form values, a validate function that will be called when
  // form values change or fields are blurred, and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      productValue: formData.productValue,
      phoneNumber: formData.customerCellphone,
      email: formData.customerMail,
      documentId: formData.customerDocument,
      numberPlate: formData.NumberPlate,
      registrationId: formData.registrationId,
      city: formData.city,
      contractNumber: formData.contractNumber,
      paymentMethod: formData.customerPaymentMethod,
      name: "",
      payerPhone: formData.payerPhone
    },
    validate,
    onSubmit: async (values) => {
      setFormData({
        ...formData,
        productValue: values.productValue,
        customerDocument: values.documentId,
        customerMail: values.email,
        customerCellphone: values.phoneNumber,
        customerPaymentMethod: values.paymentMethod,
        // paymentMethodDescription: paymentMethods[values.paymentMethod-1].name,
        // paymentMethodDescription: paymentMethods.find(method => method.code == values.paymentMethod).name,
        numberPlate: values.numberPlate ? values.numberPlate : '',
        registrationId: values.registrationId ? values.registrationId : '',
        city: values.city ? values.city : '',
        contractNumber: values.contractNumber ? values.contractNumber : '',
        payerPhone: values.payerPhone
      })


      // Create Order

      let bparams = {};
      let qparams = {};



      // Prepare body and query params

      // Segurisimo 81
      if (parseInt(formData.subType) == 81) {
        bparams = {
          "telefono_pagador": values.payerPhone,
          "documento_comprador": values.documentId
        }

        qparams = {
          "telefono": values.phoneNumber,
          "operador": formData.subType,
          "valor": values.productValue,
          "descripcion": dpoReference,
          "codigo_valor": formData.productCode
        }
      }
      // Axa AP Asistencia Medica, Asistencia Dental
      else if (parseInt(formData.subType) == 82 || parseInt(formData.subType) == 83) {
        bparams = {
          "telefono_pagador": values.payerPhone,
          "documento_tomador": values.documentId
        }

        qparams = {
          "telefono": values.phoneNumber,
          "operador": formData.subType,
          "valor": values.productValue,
          "descripcion": dpoReference,
          "codigo_valor": formData.productCode
        }
      }
      // Exequiales Los Olivos
      else if (parseInt(formData.subType) == 86 || parseInt(formData.subType) == 87 || parseInt(formData.subType) == 88) {
        bparams = {
          "telefono_pagador": values.payerPhone,
          "tipo_documento": values.documentType,
          "numero_documento": values.documentId,
          "nombre_pagador": values.name,
          "email": values.email
        }

        qparams = {
          "telefono": values.phoneNumber,
          "operador": formData.subType,
          "valor": values.productValue,
          "descripcion": dpoReference,
          "codigo_valor": formData.productCode
        }
      }
      // Exequiales Los Olivos Pago de facturas
      else if (parseInt(formData.subType) == 85) {
        bparams = {
          "telefono_pagador": values.payerPhone,
          "factura_id": formData.productId,
          "tipo_documento": values.documentType,
          "numero_documento": values.documentId,
          "nombre_pagador": values.name,
          "email": values.email
        }

        qparams = {
          "telefono": values.phoneNumber,
          "operador": formData.subType,
          "valor": values.productValue,
          "descripcion": dpoReference
        }
      }
      // Directv 8
      else if (parseInt(formData.subType) == 8) {
        bparams = {
          "telefono_pagador": values.payerPhone
        }
        // In telefono send contractnumber
        qparams = {
          "telefono": values.contractNumber,
          "operador": formData.subType,
          "valor": values.productValue,
          "descripcion": dpoReference,
          "codigo_valor": formData.productCode
        }
      }
      // Recargas Celular
      else if (parseInt(formData.type) == 2) {
        bparams = {
          "telefono_pagador": values.payerPhone
        }

        qparams = {
          "telefono": values.phoneNumber,
          "operador": formData.productCode,
          "valor": values.productValue,
          "descripcion": dpoReference
        }
      }
      else {
        bparams = {
          "telefono_pagador": values.payerPhone
        }

        qparams = {
          "telefono": values.phoneNumber,
          "operador": formData.subType,
          "valor": values.productValue,
          "descripcion": dpoReference,
          "codigo_valor": formData.productCode
        }
      }

      const environment = PROD_MODE.toUpperCase() == "TRUE" ? "production" : "sandbox";

      // Create order in the payment manager
      const orderInfo = {
        website: "fullmovil.com.co",
        paymentGateway: "wompi",
        environment: environment,
        paymentReference: paymentReference,
        code: dpoReference,
        user: {
          type: "Natural",
          name: values.name,
          email: values.email,
          phone: values.phoneNumber,
          docNumber: values.documentId,
          docType: values.documentType,
          payerPhone: values.payerPhone
        },
        userExtra: {
        },
        payment: {
          method: "",
          bank: "",
          description: ""
        },
        status: 0,
        statusMsg: "CREATED",
        product: {
          "categoryId": formData.type,
          "name": formData.typeDescription,
          "operator": formData.productDescription,
          "value": values.productValue
        },
        hparams: {
        },
        qparams: {
          ...qparams
        },
        bparams: {
          ...bparams
        },
        serviceResponse: {
          status: "PENDING"
        }
      }

      // Get Signature
      const productValueCents = parseInt(values.productValue) * 100;
      const currency = "COP";

      try {
        const response = await axios.get(`${PAYMENTMANAGER_API_HOST}/utils/wompi/v1/integrity-signature/${paymentReference}/${productValueCents}/${currency}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        integritySign = response.data.data.resultIntegrity;
      } catch (error) {
        console.log(error);
      }

      // console.log(orderInfo)
      // console.log(integritySign)

      // Crete order in payment manager
      const order = await axios.post(`${PAYMENTMANAGER_API_HOST}/orders/wompi/v1/`, orderInfo, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        },
      });

      // console.log(order)

      // If order has been created, submit form
      if (order.status == 201) {
        const publicKey = PROD_MODE.toUpperCase() == "TRUE" ? PUBLIC_KEY : PUBLIC_KEY_TEST;
        const redirectUrl = PROD_MODE.toUpperCase() == "TRUE" ? PRODUCTION_ENV_URL : TEST_ENV_URL;
        const resumenCompraUrl = redirectUrl + '/#/resumen-compra/';

        const wompiCheckoutData = {
          "public-key": publicKey,
          "currency": "COP",
          "amount-in-cents": productValueCents,
          "reference": paymentReference,
          "signature:integrity": integritySign,
          "redirect-url": resumenCompraUrl,
          "customer-data:email": values.email,
          "customer-data:full-name": values.name,
          "customer-data:phone-number": values.phoneNumber,
          "customer-data:legal-id": values.documentId,
          "customer-data:legal-id-type": "CC"
        }

        let wompiQueryParams = Object.keys(wompiCheckoutData).map(function (key) {
          return key + '=' + wompiCheckoutData[key];
        }).join('&');
        document.location.href = `https://checkout.wompi.co/p/?${wompiQueryParams}`;

      } else {
        setErrorCretateOrder(true);
      }
      // values.paymentMethod !== 5 && setPage(page + 1);
    },
  });


  const handleKeyPress = (e) => {
    var code = e.which ? e.which : e.keyCode;
    if (code != 13) {
      if (e.target.value.length >= 10) {
        e.preventDefault(e.validate);
      }
      if (code < 48 || code > 57) {
        e.preventDefault(e.validate);
      }
    }
  };

  const getPayment_methods = async () => {
    const resp = await getPaymentMethods();
    setPaymentMethods(resp);
    paymentMethodsReduce = paymentMethods;
  };

  useEffect(() => {
    getPayment_methods();
    inputReference.current.focus();
  }, []);

  useEffect(() => {
    if (productPrice < 20000) {
      setPaymentMethodsReduce(paymentMethods.filter((method) => method.code != 1 && method.code != 3))
    } else {
      setPaymentMethodsReduce(paymentMethods)
    }
  }, [productPrice, paymentMethods])

  return (
    <div className="w-full lg:h-[60vh] lg:overflow-y-scroll px-4">
      <p className="md:text-2xl text-[#28367B] font-['Roboto', Sans-serif] font-extrabold tracking-tight leading-snug mb-4 text-left">
        Finalizar
      </p>
      <div className="flex justify-center">
        <form className="w-full max-w-full lg:max-w-lg m-0" onSubmit={formik.handleSubmit}>
          <Summary
            page={page}
            setPage={setPage}
            formData={formData}
            setFormData={setFormData}
          />

          <div className="w-full mt-3 mr-auto mb-3 ml-auto lg:mb-0 lg:mt-2">
            <label className="block font-semibold text-sm text-gray-700">
              Valor del PIN
            </label>
            <div className="mt-1">
              <input
                type="tel"
                ref={inputReference}
                className='px-3 sm:text-sm h-10 w-full rounded-md shadow-sm border
            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 focus:bg-white'
                placeholder="ingrese un valor"
                name="productValue"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.productValue}
                pattern="[0-9]{4,10}"
                required
                onKeyPress={handleKeyPress}
                disabled={true}
              />
              {formik.errors.productValue && formik.touched.productValue && (
                <div className="italic text-red-500">
                  {formik.errors.productValue}
                </div>
              )}
            </div>
          </div>


          <div className="w-full mt-3 mr-auto mb-3 ml-auto lg:mb-0 lg:mt-2">
            <label className="block font-semibold text-sm text-gray-700">
              Celular
            </label>
            <div className="mt-1">
              <input
                type="tel"
                ref={formData.type != 2 ? inputReference : undefined}
                className="px-3 sm:text-sm h-10 w-full rounded-md shadow-sm border
            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 focus:bg-white"
                placeholder="ingrese el numero
            celular"
                name="phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                pattern="[0-9]{10}"
                required
                onKeyPress={handleKeyPress}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <div className="italic text-red-500">
                  {formik.errors.phoneNumber}
                </div>
              )}
            </div>
          </div>

          <div className="w-full mt-3 mr-auto mb-3 ml-auto lg:mb-0 lg:mt-2">
            <label className="block font-semibold text-sm text-gray-700">
              Correo
            </label>
            <div className="mt-1">
              <input
                type="email"
                className="px-3 sm:text-sm h-10 w-full rounded-md shadow-sm border
            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 focus:bg-white"
                placeholder="correo@correo.com"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                required
              />
              {formik.errors.email && formik.touched.email && (
                <div className="italic text-red-500">{formik.errors.email}</div>
              )}
            </div>
          </div>

          <div className="w-full mt-3 mr-auto mb-3 ml-auto lg:mb-0 lg:mt-2">
            <label className="block font-semibold text-sm text-gray-700">
              Número de Identificación
            </label>
            <div className="mt-1">
              <input
                type="tel"
                className="px-3 sm:text-sm h-10 w-full rounded-md shadow-sm border
            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 focus:bg-white"
                placeholder="documento"
                name="documentId"
                onChange={formik.handleChange}
                value={formik.values.documentId}
                onBlur={formik.handleBlur}
                required
                onKeyPress={handleKeyPress}
              />
              {formik.errors.documentId && formik.touched.documentId && (
                <div className="italic text-red-500">{formik.errors.documentId}</div>
              )}
            </div>
          </div>

          <div className="w-full mt-3 mr-auto mb-3 ml-auto lg:mb-0 lg:mt-2">
            <label className="block font-semibold text-sm text-gray-700">
              Ingrese un número telefónico para recibir confirmación de su pago vía SMS.
            </label>
            <div className="mt-1">
              <input
                type="tel"
                className="px-3 sm:text-sm h-10 w-full rounded-md shadow-sm border
            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 focus:bg-white"
                placeholder="Número telefónico"
                name="payerPhone"
                onChange={formik.handleChange}
                value={formik.values.payerPhone}
                onBlur={formik.handleBlur}
                required
              />
              {formik.errors.payerPhone && formik.touched.payerPhone && (
                <div className="italic text-red-500">{formik.errors.payerPhone}</div>
              )}
            </div>
          </div>

          {/*<div className="w-full mt-3 mr-auto mb-3 ml-auto lg:mb-0 lg:mt-2">
          <label className="block font-semibold text-sm text-gray-700">
            Metodo Pago
          </label>
          <div>
            <select
              className="mb-2 w-full rounded-md py-2 pl-3 border text-gray-700 border-gray-300
            placeholder-gray-300 focus:shadow-outline cursor-pointer"
              style={{ fontFamily: "Arial" }}
              name="paymentMethod"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.paymentMethod}
            >
              <option value={0}>
                Seleccione método
              </option>
              {paymentMethods.map((paymentMethod, index) => (
              <option key={index} value={paymentMethod.code}>
                {paymentMethod.name}
              </option>
            ))}
            </select>
            {formik.errors.paymentMethod && formik.touched.paymentMethod && (
              <div className="italic text-red-500">
                {formik.errors.paymentMethod}
              </div>
            )}
          </div>
            </div>

        <button
          className="sticky bottom-0 w-full text-center text-xl font-semibold bg-gray-800 text-white pt-3 pr-3 pb-3 pl-3
        hover:bg-gray-600 rounded-md"
          style={{ fontFamily: "Arial" }}
          type="submit"
        >
          COMPRAR
        </button>*/}

          <button
            className="sticky bottom-0 w-full text-center text-xl font-semibold bg-gray-800 text-white mt-4 pt-3 pr-3 pb-3 pl-3
          hover:bg-gray-600 rounded-md"
            style={{ fontFamily: "Arial" }}
            type="submit"
          >
            PAGAR
          </button>

          <div className="text-sm my-4 text-center font-bold">Serás redirigido a la página de pagos.</div>
          {
            errorCretateOrder && (
              <div className="italic text-red-500">Hubo un error, intenta más tarde.</div>
            )
          }

        </form>
      </div>
    </div>
  );
};

export default CheckoutSeguros;
