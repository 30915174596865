export const LosOlivosModal = ({text, isVisible, onClose, setPage, page}) => { 

  if (!isVisible) return null;
    
  return (
    <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center overflow-x-hidden overflow-y-auto`}>
        <div className='bg-white w-full md:w-[600px] flex flex-col pb-2 h-[calc(100%-4rem)] sm:h-auto mb-2'>
          <button className="text-black text-xl place-self-end mr-2" onClick={() => onClose()}>X</button>
              <img
                src={text.image}
                alt={text.description}
                height="30px"
                className="block w-20 h-auto m-auto"
              />
            {
              Object.values(text.info).map((item, index) => (
                <div key={index}>
                  <div className="bg-white px-2 py-2 font-semibold">
                    <h2>{item.title}</h2>
                    {
                      typeof item.text == 'string' && (
                        <p className="text-sm font-medium text-center">{item.text}</p>
                      )
                    }

                    {
                      typeof item.text == 'object' && (
                        <ul className="pl-1 md:pl-6 list-none text-center">
                        {
                          item.text.map((subitem, index) => (
                            <li key={index} className="text-sm font-medium p-1">{subitem}</li>
                          ))
                        }
                        </ul>
                      )
                    }
                    
                    
                       
                  </div>
                  
                </div>
              ))
            }

          <button className="bg-lime-600 customFont text-white mt-4 w-full md:w-1/3 m-auto py-2 px-3 pb-2 mb-3" onClick={() => setPage(page+1)}>Continuar</button>
        </div>
        
    </div>
  )
}
