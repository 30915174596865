import React, { useEffect } from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/Home';

import "./styles.css";
import { BACKGROUND_IMAGE, PRODUCTION_ENV_URL, PROD_MODE, TEST_ENV_URL } from '../lib/envariables';

function Home() {

  const redirectUrl = PROD_MODE.toUpperCase() == "TRUE" ? PRODUCTION_ENV_URL : TEST_ENV_URL;

  // In order to redirect to the summary page, and avoid the # router, I have to do this
  // Check if there are query params on the URL and if it fits ?id= which is the way wompi
  // send the TXID, redirect, if not stay on page.

  useEffect(() => {
    if (window.location.search != '' && window.location.search.includes('?id=')) {

      window.location.href = redirectUrl + `/#/resumen/compra/${window.location.search}`
    }
    
  }, [redirectUrl]);


  const mainStyle = {
    height: "100%",
    background: `url(${BACKGROUND_IMAGE}) no-repeat center center fixed`,
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    BackgroundSize: "cover"
  }


  return (
    <div className="flex flex-col min-h-screen overflow-hidden">


      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow" style={mainStyle}>

        {/*  Page sections */}
        <HeroHome />


      </main>

    </div>
  );
}

export default Home;