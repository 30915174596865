import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getSubTypes, getSubTypesFullSeguro, getOperatorInfo } from "../../services/digitalProducts";
import { brandLayout } from "../data/productTypes";

import "./styles.css";
import { LosOlivosModal } from "../Modal/LosOlivosModal";
import { LosOlivosPagosModal } from "../Modal/LosOlivosPagosModal";

function ProductSubTypes({ page, setPage, formData, setFormData }) {

  const params = useParams();

  const [subTypes, setSubTypes] = useState([]);
  const [noProductsText, setNoProductsText] = useState('');
  const [losOlivosPlanContent, setLosOlivosPlanContent] = useState();
  const [losOlivosPagosContent, setLosOlivosPagosContent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showPagosModal, setShowPagosModal] = useState(false);

  useEffect(() => {
    getSubtypes();
  }, [formData.type]);

  useEffect(() => {
    const timer = setTimeout(() => {
      subTypes.length == 0 ? setNoProductsText('Lo sentimos, aún estamos trabajando para ofrecerte estos servicios') : setNoProductsText('');
    }, 1500);
    return () => clearTimeout(timer);
    
  }, [subTypes])

  const getSubtypes = async () => {
    if (brandLayout.hasOwnProperty(params.brandId)) {
      setSubTypes(await getSubTypesFullSeguro(formData.type));
    } else {
      setSubTypes(await getSubTypes(formData.type));
    }      
  };

  const  getLosOlivosPlanInfo = async (planId) => {
    return await getOperatorInfo(planId);
  }


  const handleClick = async (props) => {
    const { code, description, imageUrl } = props;

    setFormData({
      ...formData,
      subType: code,
      subTypeDescription: description,
      subTypeImageUrl: imageUrl,
    });

    
    if (formData.type == 17) {

      if (code == 85) {
        
        const losOlivosPagosInfo = {
          text: description,
          image: imageUrl
        }

        setLosOlivosPagosContent(losOlivosPagosInfo);
        
        setShowPagosModal(true)
      } else {
        const losOlivosPlanInfo = await getLosOlivosPlanInfo(code);

        setLosOlivosPlanContent(losOlivosPlanInfo[0])
        setShowModal(true)
      }

      

    } else {

      if (code == 8) {
        setPage(page + 2);
      } else {
        setPage(page + 1);
      }

    }
    
  };

  const SubTypeCard = ({ subType }) => {
    return (
      <div onClick={() => handleClick(subType)} className="flex flex-col shadow-lg rounded-lg bg-white items-center overflow-hidden cursor-pointer hover:shadow-xl active:shadow">
        <img
          src={subType.imageUrl}
          alt={subType.description}
          height="30px"
          className="block w-[150px] h-auto"
        />
        {/*<p>{subType.description}</p>*/}
      </div>
    );
  };


  return (
    <>
      
      <LosOlivosModal isVisible={showModal} onClose={() => setShowModal(false)} text={losOlivosPlanContent} setPage={setPage} page={page} />
      
      <LosOlivosPagosModal isVisible={showPagosModal} onClose={() => setShowPagosModal(false)} text={losOlivosPagosContent} setPage={setPage} page={page} formData={formData} setFormData={setFormData} />

      <p className="text-[#28367B] font-['Roboto', Sans-serif] font-extrabold md:text-2xl tracking-tight leading-snug mb-4 text-left">
        Proveedor
      </p>
      
          <div className="flex flex-row flex-wrap justify-center gap-3">
            { subTypes.length > 0 ?       
              subTypes.length == 1 && formData.type == 2
              ? handleClick(subTypes[0])
              : subTypes.map((subType) => (
                <SubTypeCard key={subType.code} subType={subType} />
                ))
              : <p className="flex justify-center text-gray-600">{noProductsText}</p>
            }
          </div>
      
      
    </>
  );
}

export default ProductSubTypes;
