import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReactLoading from "react-loading";
import cookie from "js-cookie";
import * as Yup from "yup";
import { Modal } from "../Modal";
import { useNavigate } from "react-router-dom";

import { createOrder } from "../../../services/digitalProducts";
import { tgetTokenForNequi, tgetStatusTokenForNequi } from "../../../services/paymentGateway";
import nequiIcon from "../../../images/nequi.png"
import { PRODUCTION_ENV_URL, PROD_MODE, TEST_ENV_URL } from "../../../lib/envariables";

export const FormNequi = ({ formData, setFormData }) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    nequiPhone: "",
    paymentToken: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const redirectUrl = PROD_MODE.toUpperCase() == "TRUE" ? PRODUCTION_ENV_URL : TEST_ENV_URL;


  const createNequiOrder = async(tokenId, values) => {
    const requestData = {
      gatewaySessionId: sessionStorage.getItem("sessionId"),
      type: formData.type,
      subType: formData.subType,
      productId: formData.productCode ? formData.productCode : 999,
      reference: formData.customerCellphone,
      value: formData.productValue,
      paymentMethod: 2,
      paymentToken: tokenId,
      productData: {},
      redirectUrl: redirectUrl + '/#/payment/summary/',
      paymentData: {
        docType:formData.customerDocumentType ? formData.customerDocumentType : 'cc',
        docNumber:formData.customerDocument ? formData.customerDocument : '9999999',
        customerMail: formData.customerMail ? formData.customerMail : 'johndoe@gmail.com',
        name: values.name ? values.name : 'John Doe',
        nequiPhone: values.nequiPhone ? values.nequiPhone : '3991111111',
        numberPlate: formData.numberPlate ? formData.numberPlate : 'XXX000',
        registrationId: formData.registrationId ? formData.registrationId : '000000',
        city: formData.city ? formData.city : '00',
        contractNumber: formData.contractNumber ? formData.contractNumber : '00',
        payerPhone: values.payerPhone ? values.payerPhone : values.nequiPhone,
        payerDocType: formData.customerDocumentType ? formData.customerDocumentType : 'cc',
        payerDocNumber: formData.customerDocument ? formData.customerDocument : '9999999',
        payerCustomerMail: formData.customerMail ? formData.customerMail : 'johndoe@gmail.com',
        payerName: values.name ? values.name : 'John Doe',
      },
    }
    const resp = await createOrder(requestData);
    if (!resp.error && resp.success) {
      cookie.set(
        "order",
        JSON.stringify({
          ...resp,
          subTypeDescription: formData.subTypeDescription,
          productDescription: formData.productDescription,
          customerCellphone: formData.customerCellphone,
          customerMail: formData.customerMail,
          paymentMethodDescription: formData.paymentMethodDescription,
          payerPhone: formData.payerPhone
        }),
        {
          path: "/",
        }
      );
      navigate("/payment/summary");
    } else {
      setAlertMessage(resp.message);
      setStateModal(true);
    }
  }

  const createTokenNequi = async (values) => {
    const requestToken = {
      phone_number: values.nequiPhone,
    };
    const resp = await tgetTokenForNequi(requestToken);

    let approvedToken;
    
    async function subscribe() {
      
      const tokenStatus = await tgetStatusTokenForNequi(resp.data.id);
    
      if (tokenStatus.data.status == "APPROVED") {  
        setState({ ...state, paymentToken: tokenStatus.data.id });
        approvedToken = tokenStatus.data.id;   
        
      } else {
        await subscribe();        
      }
    }

    await subscribe();

    return approvedToken;

  };
 
  const handleKeyPress = (e) => {
    var code = e.which ? e.which : e.keyCode;
    if (code != 13) {
      if (e.target.value.length >= 10) {
        e.preventDefault(e.validate);
      }
      if (code < 48 || code > 57) {
        e.preventDefault(e.validate);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex z-10 justify-center items-center transition">
          <div className="bg-white opacity-30 fixed inset-0 z-20" />
          <ReactLoading
            type="spin"
            color="black"
            width={"50px"}
            className="z-30"
          />
        </div>
      )}
      {stateModal && <Modal text={alertMessage} setStateModal={setStateModal} />}
      <Formik
        initialValues={{
          nequiPhone: formData.nequiPhone,
          name: formData.name,
          payerPhone: formData.payerPhone,
        }}
        validationSchema={Yup.object({
          nequiPhone: Yup.number()
            .max(9999999999, "Se deben ingresar 10 caracteres").required("Campo obligatorio"),
          name: Yup.string().matches(/^[A-Za-z ]*$/, 'Debe ser un nombre válido').min(5, 'Mínimo 5 caracteres').required("Campo obligatorio"),
          payerPhone: Yup.number()
            .max(9999999999, "Se deben ingresar 10 caracteres").required("Campo obligatorio"),
        })}
        onSubmit={async (values) => {
          setIsLoading(true);
          const tokenId = await createTokenNequi(values);
          
          if (tokenId) {
            setAlertMessage("Estamos procesando el pago...");
            setStateModal(true);
            await createNequiOrder(tokenId, values);
          }

          setIsLoading(false);
        }}
      >
        <Form className="w-full max-w-lg bg-white shadow-md rounded px-8 py-6 mb-4 mt-2">
          <div className="flex flex-row justify-center align-middle mb-4">
            <img src={nequiIcon} alt="NEQUI" className="w-8 h-8 mr-2" />
            <h1 className="text-[#001174] text-2xl">Nequi</h1>
          </div>
          
          <div className="block text-gray-800 font-bold mb-6 text-xl">
            Total a pagar: ${new Intl.NumberFormat('es-CO').format(formData.productValue)}
          </div>        

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="name"
          >
            Nombre Completo
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="name"
            type="text"
          />
          <div className="text-red-600">
            <ErrorMessage name="name" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="nequiPhone"
          >
            Número de teléfono registrado con Nequi
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="nequiPhone"
            type="tel"
            onKeyPress={handleKeyPress}
          />
          <div className="text-red-600">
            <ErrorMessage name="nequiPhone" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="payerPhone"
          >
            Ingrese un número telefónico para recibir confirmación de su pago vía SMS.
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="payerPhone"
            type="tel"
          />
          <div className="text-red-600">
            <ErrorMessage name="payerPhone" />
          </div>

          <div className="text-sm my-4 text-center font-semibold">Recuerda que debes contar con la APP de Nequi instalada en tu celular para poder completar el pago usando este método.</div>

          <div className="text-sm my-4 text-center">Al presionar PAGAR estás aceptando los <a href="https://fullmovil.com.co/terminos-y-condiciones/" target="_blank" rel="noreferrer" className=" text-blue-500">Términos y Condiciones</a></div>

          <button
            className="bg-lime-600 customFont text-white mt-4 w-full py-2 px-3"
            type="submit"
          >
            {isLoading ? <>PROCESANDO...</> : <>PAGAR</>}
          </button>

        
        </Form>
      </Formik>
    </>
  );
};
