import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { getFacturasExequiales } from "../../services/digitalProducts";

export const LosOlivosPagosModal = ({text, isVisible, onClose, setPage, page, formData, setFormData}) => { 
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noFacturas, setNoFacturas] = useState(false);
  const [docType, setDocType] = useState("");
  const [docNumber, setDocNumber] = useState("");

  if (!isVisible) return null;

  const handleKeyPress = (e) => {
    var code = e.which ? e.which : e.keyCode;
    if (code != 13) {
      if (e.target.value.length >= 10) {
        e.preventDefault(e.validate);
      }
      if (code < 48 || code > 57) {
        e.preventDefault(e.validate);
      }
    }
  };

  const submitHandler = async (tipoDocumento, numeroDocumento) => {

    formData.customerDocument = numeroDocumento;
    formData.customerDocumentType = tipoDocumento;
    setFormData(formData);

    cookie.set("formData", JSON.stringify(formData), {
      path: "/",
    });

    let facturas = [];
    let filteredFacturas = [];
    
    facturas = await getFacturasExequiales(tipoDocumento, numeroDocumento);

    filteredFacturas = facturas.response.filter(factura => {
      return factura.pago_recibido !== true;
    });

    // console.log(filteredFacturas)

    setIsLoading(false);
    setProducts(filteredFacturas);

    // console.log('Products: ', products);

    if (filteredFacturas.length > 0) {
      setPage(page + 1);
    } else {
      setNoFacturas(true);
    }
    
    
  };
    
  return (
    <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center overflow-x-hidden overflow-y-auto`}>
        <div className='bg-white w-full md:w-[600px] flex flex-col pb-2 h-[calc(100%-4rem)] sm:h-auto mb-2'>
          <button className="text-black text-xl place-self-end mr-2" onClick={() => onClose()}>X</button>
              <img
                src={text.image}
                alt={text.description}
                height="30px"
                className="block w-20 h-auto mx-auto my-3"
              />
              <Formik
        initialValues={{
          documentType: "",
          documentNumber: "",
        }}
        validationSchema={Yup.object({
          documentType: Yup.string().required("Campo obligatorio"),
          documentNumber: Yup.number().required("Campo obligatorio"),
        })}
        onSubmit={async (values) => {
          setNoFacturas(false);
          setIsLoading(true);
          submitHandler(values.documentType, values.documentNumber)
        }}
      >
        <Form className="w-full max-w-lg bg-white shadow-md rounded px-8 py-6 mb-4 mt-2">
          <div className="flex flex-row justify-center align-middle mb-4">
            
            <h1 className="text-[#001174] text-2xl">Consulta tus Pagos</h1>
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="documentType"
          >
            Tipo de documento
          </label>
          <Field
            className="bg-[#F6F6F6] shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="documentType"
            as="select"
          >
            <option value="0">Selecciona un tipo de documento</option>
            <option value="cedula">Cédula de ciudadanía</option>
          </Field>
          <div className="text-red-600">
            <ErrorMessage name="documentType" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="documentNumber"
          >
            Número de documento
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="documentNumber"
            type="tel"
            onKeyPress={handleKeyPress}
          />
          <div className="text-red-600">
            <ErrorMessage name="documentNumber" />
          </div>

          <button
            className="bg-lime-600 customFont text-white mt-4 w-full py-2 px-3"
            type="submit"
          >
            {
              isLoading ? (<>CONSULTANDO...</>) : (<>Consultar</>)
            }
          </button>

          {
            noFacturas && 
            (
              <div className="text-red-600 text-center text-lg mt-1">
              No tiene facturas pendientes por pagar
              </div>
            )
          }
                  
        </Form>
      </Formik>
        </div>
        
    </div>
  )
}
