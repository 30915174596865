export const cities = [{
  "id": 2,
  "name": "2 - ABEJORRAL"
},
{
  "id": 232,
  "name": "232 - ACACIAS"
},
{
  "id": 150,
  "name": "150 - AGUA DE DIOS"
},
{
  "id": 196,
  "name": "196 - AGUACHICA"
},
{
  "id": 102,
  "name": "102 - AGUADAS"
},
{
  "id": 3,
  "name": "3 - AMALFI"
},
{
  "id": 351,
  "name": "351 - AMBALEMA"
},
{
  "id": 4,
  "name": "4 - ANDES"
},
{
  "id": 103,
  "name": "103 -ANSERMA"
},
{
  "id": 8,
  "name": "8 - APARTADÓ"
},
{
  "id": 292,
  "name": "292 - APIA"
},
{
  "id": 410,
  "name": "410- ARAUCA"
},
{
  "id": 280,
  "name": "280 - ARMENIA"
},
{
  "id": 352,
  "name": "352 - ARMERO"
},
{
  "id": 242,
  "name": "242 - BARBACOAS"
},
{
  "id": 302,
  "name": "302 - BARICHARA"
},
{
  "id": 303,
  "name": "303 - BARRANCABERMEJA"
},
{
  "id": 40,
  "name": "40 - BARRANQUILLA"
},
{
  "id": 293,
  "name": "293 - BELEN DE UMBRIA"
},
{
  "id": "50C",
  "name": "50C - BOGOTA ZONA CENTRO"
},
{
  "id": "50N",
  "name": "50N - BOGOTA ZONA NORTE"
},
{
  "id": "50S",
  "name": "50S - BOGOTA ZONA SUR"
},
{
  "id": 5,
  "name": "5 - BOLIVAR (ANTIOQUIA)"
},
{
  "id": 122,
  "name": "122 - BOLIVAR (CAUCA)"
},
{
  "id": 300,
  "name": "300 - BUCARAMANGA"
},
{
  "id": 372,
  "name": "372 - BUENAVENTURA"
},
{
  "id": 373,
  "name": "373 - BUGA"
},
{
  "id": 261,
  "name": "261 - CACHIRA"
},
{
  "id": 354,
  "name": "354 - CAJAMARCA"
},
{
  "id": 282,
  "name": "282 - CALARCA"
},
{
  "id": 370,
  "name": "370 - CALI"
},
{
  "id": 124,
  "name": "124 - CALOTO"
},
{
  "id": 152,
  "name": "152 - CAQUEZA"
},
{
  "id": 60,
  "name": "60 - CARTAGENA"
},
{
  "id": 375,
  "name": "375 - CARTAGO"
},
{
  "id": 15,
  "name": "CAUCASIA"
},
{
  "id": 6,
  "name": "15 - CAÑAS GORDAS"
},
{
  "id": 143,
  "name": "143 - CERETE"
},
{
  "id": 355,
  "name": "355 - CHAPARRAL"
},
{
  "id": 306,
  "name": "306 - CHARALA"
},
{
  "id": 192,
  "name": "192 - CHIMICHAGUA"
},
{
  "id": 264,
  "name": "264 - CHINACOTA"
},
{
  "id": 144,
  "name": "144 - CHINU"
},
{
  "id": 72,
  "name": "72 - CHIQUINQUIRÁ"
},
{
  "id": 154,
  "name": "154 - CHOCONTA"
},
{
  "id": 222,
  "name": "222 - CIENAGA"
},
{
  "id": 308,
  "name": "308 - CONCEPCION"
},
{
  "id": 161,
  "name": "161 - CONTRATACION"
},
{
  "id": 266,
  "name": "266 - CONVENCION"
},
{
  "id": 342,
  "name": "342 - COROZAL"
},
{
  "id": 260,
  "name": "260 - CUCUTA"
},
{
  "id": 7,
  "name": "7 - DABEIBA"
},
{
  "id": 294,
  "name": "294 - DOSQUEBRADAS"
},
{
  "id": 74,
  "name": "74 - DUITAMA"
},
{
  "id": 224,
  "name": "224 - EL BANCO"
},
{
  "id": 62,
  "name": "62 - EL CARMEN DE BOLIVAR"
},
{
  "id": 76,
  "name": "76 - EL COCUY"
},
{
  "id": 357,
  "name": "357 - ESPINAL"
},
{
  "id": 156,
  "name": "156 - FACATATIVA"
},
{
  "id": 284,
  "name": "284 - FILANDIA"
},
{
  "id": 420,
  "name": "420 - FLORENCIA"
},
{
  "id": 10,
  "name": "10 - FREDONIA"
},
{
  "id": 359,
  "name": "359 - FRESNO"
},
{
  "id": 11,
  "name": "11 - FRONTINO"
},
{
  "id": 225,
  "name": "225 - FUNDACION"
},
{
  "id": 157,
  "name": "157 - FUSAGASUGA"
},
{
  "id": 160,
  "name": "160 - GACHETA"
},
{
  "id": 78,
  "name": "78 - GARAGOA"
},
{
  "id": 202,
  "name": "202 - GARZON"
},
{
  "id": 307,
  "name": "307 - GIRARDOT"
},
{
  "id": 12,
  "name": "12 - GIRARDOTA"
},
{
  "id": 162,
  "name": "162 - GUADUAS"
},
{
  "id": 360,
  "name": "360 - GUAMO"
},
{
  "id": 126,
  "name": "126 - GUAPI"
},
{
  "id": 79,
  "name": "79 - GUATEQUE"
},
{
  "id": 362,
  "name": "362 - HONDA"
},
{
  "id": 350,
  "name": "350 - IBAGUE"
},
{
  "id": 500,
  "name": "500 - INIRIDA"
},
{
  "id": 244,
  "name": "244 - IPIALES"
},
{
  "id": 184,
  "name": "184 - ISTMINA"
},
{
  "id": 13,
  "name": "13 - ITUANGO"
},
{
  "id": 14,
  "name": "14 - JERICO"
},
{
  "id": 17,
  "name": "17 - LA CEJA"
},
{
  "id": 246,
  "name": "246 - LA CRUZ"
},
{
  "id": 106,
  "name": "106 - LA DORADA"
},
{
  "id": 166,
  "name": "166 - LA MESA"
},
{
  "id": 167,
  "name": "167 - LA PALMA"
},
{
  "id": 204,
  "name": "204 - LA PLATA"
},
{
  "id": 248,
  "name": "248 - LA UNION"
},
{
  "id": 400,
  "name": "400 - LETICIA"
},
{
  "id": 364,
  "name": "364 - LIBANO"
},
{
  "id": 146,
  "name": "146 - LORICA"
},
{
  "id": 64,
  "name": "64 - MAGANGUE"
},
{
  "id": 212,
  "name": "212 - MAICAO"
},
{
  "id": 312,
  "name": "312 - MALAGA"
},
{
  "id": 100,
  "name": "100 - MANIZALES"
},
{
  "id": 108,
  "name": "108 - MANZANARES"
},
{
  "id": 18,
  "name": "18 - MARINILLA"
},
{
  "id": "01N",
  "name": "01N - MEDELLIN NORTE"
},
{
  "id": 1,
  "name": "1 - MEDELLIN SUR"
},
{
  "id": 366,
  "name": "366 - MELGAR"
},
{
  "id": 82,
  "name": "82 - MIRAFLORES"
},
{
  "id": 520,
  "name": "520 - MITU"
},
{
  "id": 440,
  "name": "440 - MOCOA"
},
{
  "id": 65,
  "name": "65 - MOMPOS"
},
{
  "id": 83,
  "name": "83 - MONIQUIRA"
},
{
  "id": 142,
  "name": "142 - MONTELIBANO"
},
{
  "id": 140,
  "name": "140 - MONTERIA"
},
{
  "id": 110,
  "name": "110 - NEIRA"
},
{
  "id": 200,
  "name": "200 - NEIVA"
},
{
  "id": 186,
  "name": "186 - NUQUI"
},
{
  "id": 270,
  "name": "270 - OCANA"
},
{
  "id": 86,
  "name": "86 - OROCUE"
},
{
  "id": 170,
  "name": "170 - PACHO"
},
{
  "id": 112,
  "name": "112 - PACORA"
},
{
  "id": 378,
  "name": "378 - PALMIRA"
},
{
  "id": 272,
  "name": "272 - PAMPLONA"
},
{
  "id": 240,
  "name": "240 - PASTO"
},
{
  "id": 128,
  "name": "128 - PATIA EL BORDO"
},
{
  "id": 475,
  "name": "475 - PAZ DE ARIPORO"
},
{
  "id": 114,
  "name": "114 - PENSILVANIA"
},
{
  "id": 290,
  "name": "290 - PEREIRA"
},
{
  "id": 314,
  "name": "314 - PIEDECUESTA"
},
{
  "id": 206,
  "name": "206 - PITALITO"
},
{
  "id": 226,
  "name": "226 - PLATO"
},
{
  "id": 120,
  "name": "120 - POPAYAN"
},
{
  "id": 315,
  "name": "315 - PUENTE NACIONAL"
},
{
  "id": 442,
  "name": "442 - PUERTO ASIS"
},
{
  "id": 19,
  "name": "19 - PUERTO BERRIO"
},
{
  "id": 88,
  "name": "88 - PUERTO BOYACA"
},
{
  "id": 540,
  "name": "540 - PUERTO CARREÑO"
},
{
  "id": 234,
  "name": "234 - PUERTO LOPEZ"
},
{
  "id": 130,
  "name": "130 - PUERTO TEJADA"
},
{
  "id": 368,
  "name": "368 - PURIFICACION"
},
{
  "id": 180,
  "name": "180 - QUIBDO"
},
{
  "id": 90,
  "name": "90 - RAMIRIQUI"
},
{
  "id": 20,
  "name": "20 - RIONEGRO"
},
{
  "id": 115,
  "name": "115 - RIOSUCIO"
},
{
  "id": 380,
  "name": "380 - ROLDANILLO"
},
{
  "id": 210,
  "name": "210 - RIOHACHA"
},
{
  "id": 45,
  "name": "45 - SABANALARGA"
},
{
  "id": 148,
  "name": "148 - SAHAGUN"
},
{
  "id": 118,
  "name": "118 - SALAMINA"
},
{
  "id": 276,
  "name": "276 - SALAZAR"
},
{
  "id": 250,
  "name": "250 - SAMANIEGO"
},
{
  "id": 450,
  "name": "450 - SAN ANDRES"
},
{
  "id": 318,
  "name": "318 - SAN ANDRES (SANTANDER)"
},
{
  "id": 319,
  "name": "319 - SAN GIL"
},
{
  "id": 480,
  "name": "480 - SAN JOSE DEL GUAVIARE"
},
{
  "id": 214,
  "name": "214 - SAN JUAN DEL CESAR"
},
{
  "id": 346,
  "name": "346 - SAN MARCOS"
},
{
  "id": 236,
  "name": "236 - SAN MARTIN"
},
{
  "id": 320,
  "name": "320 - SAN VICENTE DE CHUCURI"
},
{
  "id": 425,
  "name": "425 - SAN VICENTE DEL CAGUAN"
},
{
  "id": 23,
  "name": "23 - SANTA BARBARA"
},
{
  "id": 24,
  "name": "24 - SANTA FE DE ANTIOQUIA"
},
{
  "id": 80,
  "name": "80 - SANTA MARTA"
},
{
  "id": 296,
  "name": "296 - SANTA ROSA DE CABAL"
},
{
  "id": 25,
  "name": "25 - SANTA ROSA DE OSOS"
},
{
  "id": 92,
  "name": "92 - SANTA ROSA DE VITERBO"
},
{
  "id": 132,
  "name": "132 - SANTANDER DE QUILICHAO"
},
{
  "id": 26,
  "name": "26 - SANTO DOMINGO"
},
{
  "id": 297,
  "name": "297 - SANTUARIO"
},
{
  "id": 27,
  "name": "27 - SEGOVIA"
},
{
  "id": 382,
  "name": "382 - SEVILLA"
},
{
  "id": 441,
  "name": "441 - Sibundoy"
},
{
  "id": 134,
  "name": "134 - SILVIA"
},
{
  "id": 68,
  "name": "68 - SIMITI"
},
{
  "id": 347,
  "name": "347 - SINCE"
},
{
  "id": 340,
  "name": "340 - SINCELEJO"
},
{
  "id": 228,
  "name": "228 - SITIONUEVO"
},
{
  "id": 51,
  "name": "51 - SOACHA"
},
{
  "id": 93,
  "name": "93 - SOATA"
},
{
  "id": 94,
  "name": "94 - SOCHA"
},
{
  "id": 321,
  "name": "321 - SOCORRO"
},
{
  "id": 95,
  "name": "95 - SOGAMOSO"
},
{
  "id": 41,
  "name": "41 - SOLEDAD"
},
{
  "id": 28,
  "name": "28 - SONSON"
},
{
  "id": 29,
  "name": "29 - SOPETRAN"
},
{
  "id": 32,
  "name": "32 - TAMESIS"
},
{
  "id": 33,
  "name": "33 - TITIRIBI"
},
{
  "id": 384,
  "name": "384 - TULUA"
},
{
  "id": 252,
  "name": "252 - TUMACO"
},
{
  "id": 70,
  "name": "70 - TUNJA"
},
{
  "id": 254,
  "name": "254 - TUQUERRES"
},
{
  "id": 34,
  "name": "34 - TURBO"
},
{
  "id": 172,
  "name": "172 - UBATE"
},
{
  "id": 35,
  "name": "35 - URRAO"
},
{
  "id": 190,
  "name": "190 - VALLEDUPAR"
},
{
  "id": 324,
  "name": "324 - VELEZ"
},
{
  "id": 230,
  "name": "230 - VILLAVICENCIO"
},
{
  "id": 37,
  "name": "37 - YARUMAL"
},
{
  "id": 38,
  "name": "38 - YOLOMBO"
},
{
  "id": 470,
  "name": "470 - YOPAL"
},
{
  "id": 326,
  "name": "326 - ZAPATOCA"
},
{
  "id": 176,
  "name": "176 - ZIPAQUIRA"
}
]