import { useState } from "react";
import Card from "react-credit-cards";
import ReactLoading from "react-loading";
import cookie from "js-cookie";

import "react-credit-cards/es/styles-compiled.css";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatName,
} from "./utils";

import "./styles.css";

import { createOrder } from "../../services/digitalProducts";
import { useNavigate } from "react-router-dom";
import { Modal } from "../paymentMethods/Modal";
import { tgetTokenForCard } from "../../services/paymentGateway";
import { PROD_MODE, PRODUCTION_ENV_URL, TEST_ENV_URL } from "../../lib/envariables";

export const CardPayment = ({ formData, setFormData }) => {
  const [state, setState] = useState({
    number: formData.cardNumber,
    name: formData.cardUserName,
    expiry: formData.cardExpiry,
    cvc: formData.cardCvc,
    paymentToken: "",
    issuer: "",
    focused: "",
    payerPhone: formData.payerPhone
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [stateModal, setStateModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const redirectUrl = PROD_MODE.toUpperCase() == "TRUE" ? PRODUCTION_ENV_URL : TEST_ENV_URL;

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setState({
        ...state,
        issuer,
      });
    }
  };

  const handleInputFocus = ({ target }) => {
    setState({
      ...state,
      focused: target.name,
    });
  };

  const [isErrorCardNumber, setIsErrorCardNumber] = useState(false);
  const [isErrorCardName, setIsErrorCardName] = useState(false);
  const [isErrorCardExpiry, setIsErrorCardExpiry] = useState(false);
  const [isErrorCardCvc, setIsErrorCardCvc] = useState(false);
  const [isErrorPayerPhone, setIsErrorPayerPhone] = useState(false);

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "name") {
      target.value = formatName(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    setState({ ...state, [target.name]: target.value });
  };

  const createOrderCard = async (tokenId) => {
    const request = {
      gatewaySessionId: sessionStorage.getItem("sessionId"),
      type: formData.type,
      subType: formData.subType,
      productId: formData.productCode ? formData.productCode : 999,
      reference: formData.customerCellphone,
      value: formData.productValue,
      paymentMethod: 1,
      paymentToken: tokenId,
      productData: {},
      redirectUrl: redirectUrl + '/#/payment/summary/',
      paymentData: {
        docType:formData.customerDocumentType ? formData.customerDocumentType : 'cc',
        docNumber:formData.customerDocument ? formData.customerDocument : '9999999',
        customerMail: formData.customerMail ? formData.customerMail : 'johndoe@gmail.com',
        name: state.name ? state.name : 'John Doe',
        nequiPhone: formData.nequiPhone ? formData.nequiPhone : '3991111111',
        numberPlate: formData.numberPlate ? formData.numberPlate : 'XXX000',
        registrationId: formData.registrationId ? formData.registrationId : '000000',
        city: formData.city ? formData.city : '00',
        contractNumber: formData.contractNumber ? formData.contractNumber : '00',
        payerPhone: state.payerPhone ? state.payerPhone : '',
        payerDocType: formData.customerDocumentType ? formData.customerDocumentType : 'cc',
        payerDocNumber: formData.customerDocument ? formData.customerDocument : '9999999',
        payerCustomerMail: formData.customerMail ? formData.customerMail : 'johndoe@gmail.com',
        payerName: state.name ? state.name : 'John Doe',
      },
    };
    const resp = await createOrder(request);

    if (!resp.error && resp.success) {
      cookie.set(
        "order",
        JSON.stringify({
          ...resp,
          subTypeDescription: formData.subTypeDescription,
          productDescription: formData.productDescription,
          customerCellphone: formData.customerCellphone,
          customerMail: formData.customerMail,
          paymentMethodDescription: formData.paymentMethodDescription,
          customerDocument: formData.customerDocument,
          customerDocumentType: formData.customerDocumentType,
          payerPhone: formData.payerPhone
        }),
        {
          path: "/",
        }
      );
      navigate("/payment/summary");
    } else {
      setAlertMessage(resp.message);
      setStateModal(true);
    }
  };

  const createTokenCard = async () => {
    const expMonth = state.expiry.substring(0, 2);
    const expYear = state.expiry.substring(3);

    const requestToken = {
      number: state.number.replace(/\s+/g, ""),
      exp_month: expMonth,
      exp_year: expYear,
      cvc: state.cvc,
      card_holder: state.name,
    };
    const resp = await tgetTokenForCard(requestToken);

    if (resp.status == "CREATED") {
      setState({ ...state, paymentToken: resp.data.id });
      return resp.data.id;
    } else {
      resp.error.messages.number
        ? setAlertMessage(resp.error.messages.number)
        : setAlertMessage(resp.error.messages.card_holder);
      setStateModal(true);
    }
  };

  const validateExpiry = (expiryDate) => {
    let expiryPattern = /^(0|1)[0-9]\/(2)[0-9]{2}$/i;
   
    if (!expiryPattern.test(expiryDate)) {
      let date_array = expiryDate.split('/');
      let month = date_array[0] - 1;
      let year = date_array[1];

      const today_date = new Date();

      if (year > today_date.getFullYear().toString().slice(-2))
        { 
            return true;
        } 
        else if (year == today_date.getFullYear().toString().slice(-2))
        {
            if (month > today_date.getMonth())
            {
              return true;
            } else if (month == today_date.getMonth()) {
              return true;
            } else {
              return false;
            }
        } else {
            return false;
        }
    } else {
      return false;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errorCount= 5;

    if (e.target.number.value.length < 16) {
      setIsErrorCardNumber(true)
    } else {
      setIsErrorCardNumber(false)
      errorCount--;
    }

    if (e.target.name.value.length < 5) {
      setIsErrorCardName(true)
    } else {
      setIsErrorCardName(false)
      errorCount--;
    }

    if (e.target.expiry.value.length < 5) {
      setIsErrorCardExpiry(true)
    } else if(validateExpiry(e.target.expiry.value) == false) {
      setIsErrorCardExpiry(true)
    } else {
      setIsErrorCardExpiry(false)
      errorCount--;
    }

    if (e.target.cvc.value.length < 3) {
      setIsErrorCardCvc(true)
    } else {
      setIsErrorCardCvc(false)
      errorCount--;
    }

    if (e.target.payerPhone.value.length < 10 || e.target.payerPhone.value.length > 10) {
      setIsErrorPayerPhone(true)
    } else {
      setIsErrorPayerPhone(false)
      errorCount--;
    }

    if (errorCount == 0) {
      setIsLoading(true);
      const tokenId = await createTokenCard();
      tokenId && (await createOrderCard(tokenId));

      setIsLoading(false);
    }

  };

  return (
    <div className="w-full">
      {isLoading && (
        <div className="fixed inset-0 flex z-10 justify-center items-center transition">
          <div className="bg-white opacity-30 fixed inset-0 z-20" />
          <ReactLoading
            type="spin"
            color="black"
            width={"50px"}
            className="z-30"
          />
        </div>
      )}
      {stateModal && (
        <Modal text={alertMessage} setStateModal={setStateModal} />
      )}
      
      <div className="block text-gray-800 font-bold mb-6 mt-3 text-xl text-center">
        Total a pagar: ${new Intl.NumberFormat('es-CO').format(formData.productValue)}
      </div>
      <h1 className='text-[#28367B] font-["Roboto", Sans-serif] font-extrabold'>
        Datos Tarjeta de Credito
      </h1>
      <div className="w-full -mx-3">
        <Card
          number={state.number}
          name={state.name}
          expiry={state.expiry}
          cvc={state.cvc.replace(/[0-9]/g, "X")}
          focused={state.focused}
          callback={handleCallback}
        />
      </div>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3">
            <input
              type="tel"
              name="number"
              placeholder="Número Tarjeta"
              pattern="[\d| ]{16,22}"
              defaultValue={state.number}
              
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className="appearance-none block sm:text-sm h-10 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 mb-3 leading-tight  focus:bg-white"
            />
            
              {isErrorCardNumber && (
                <div className="text-red-600 w-full my-1">
                Campo obligatorio
                </div>
              )}
            
          </div>
          <div className="w-full px-3">
            <input
              type="text"
              name="name"
              placeholder="Nombre en la Tarjeta"
              defaultValue={state.name}
              
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className="appearance-none block sm:text-sm h-10 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 mb-3 leading-tight focus:bg-white"
            />
            {isErrorCardName && (
              <div className="text-red-600 w-full my-1">
                Campo obligatorio, mínimo 5 caracteres
              </div>
            )}
          </div>
          
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <input
              type="tel"
              name="expiry"
              placeholder="Fecha expiración MM/AA"
              pattern="\d\d/\d\d"
              defaultValue={state.expiry}
              
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className="appearance-none block sm:text-sm h-10 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 mb-3 leading-tight focus:bg-white"
            />
            {isErrorCardExpiry && (
              <div className="text-red-600 w-full my-1">
              Campo obligatorio
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-3">
            <input
              type="password"
              name="cvc"
              placeholder="CVC"
              pattern="\d{3,4}"
              defaultValue={state.cvc}
              
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className="form-control appearance-none block sm:text-sm h-10 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 leading-tight focus:bg-white"
            />
            {isErrorCardCvc && (
              <div className="text-red-600 w-full my-1">
              Campo obligatorio
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3">
            <label
              className="block text-gray-700 text-sm font-bold mt-4"
              htmlFor="payerPhone"
            >
              Ingrese un número telefónico para recibir confirmación de su pago vía SMS.
            </label>
                <input
                  type="tel"
                  name="payerPhone"
                  maxLength={10}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}          
                  className="appearance-none block sm:text-sm h-10 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 mb-3 leading-tight  focus:bg-white"
                />
                
                  {isErrorPayerPhone && (
                    <div className="text-red-600 w-full my-1">
                    Campo obligatorio
                    </div>
                  )}
                
              </div>
        </div>

        <div className="text-sm my-4 text-center">Al presionar PAGAR estás aceptando los <a href="https://fullmovil.com.co/terminos-y-condiciones/" target="_blank" rel="noreferrer" className=" text-blue-500">Términos y Condiciones</a></div>
        <div className="w-full">
          <button className="btn btn-primary btn-block w-full bg-amber-400 rounded py-3 px-4">
            {isLoading ? <>PROCESANDO...</> : <>PAGAR</>}
          </button>
        </div>
      </form>
    </div>
  );
};
