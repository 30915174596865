import React, { useState, useEffect } from "react";
import { getProducts, getCertificadoVehicular, getOperatorInfo, getFacturasExequiales } from "../../services/digitalProducts";
import ReactLoading from "react-loading";
import moment from "moment/moment";

function ProductList({ page, setPage, formData, setFormData }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getproducts = async (type, subType) => {

    let products = [];
    let facturas = [];
    let filteredFacturas = [];

    if(type == 14 && subType == 62) {
      products = await getCertificadoVehicular();
    } else if(type == 14 && subType == 39) {
      products = await getOperatorInfo(subType);
    } else if(type == 17 && subType == 85) {
      facturas = await getFacturasExequiales(formData.customerDocumentType, formData.customerDocument);

      filteredFacturas = facturas.response.filter(factura => {
        return factura.pago_recibido !== true;
      });

      // Sort Facturas by factura_id
      filteredFacturas.sort((a, b) => (a.factura_id > b.factura_id) ? 1 : -1)

      products = filteredFacturas.map((factura) => {
        
        // let fechaVencimiento = factura.fecha_vencimiento.replace('12:00:00 a.m.', '');

        let fechaVencimiento = moment(factura.fecha_vencimiento).format('DD MMM YYYY');

        moment().format('DD-MMM-YY')
        
        return {
          code: factura.factura_id,
          description: factura.nombre_pagador,
          vencimiento: fechaVencimiento,
          documento: factura.numero_documento,
          value: factura.valor_pago,
          imageUrl: formData.subTypeImageUrl
        }
      })

    } else {
      products = await getProducts(type, subType);
    }    
   

    setIsLoading(false);
    setProducts(products);

  };

  useEffect(() => {
    getproducts(formData.type, formData.subType);
  }, []);

  const handleClick = (props) => {
    const { code, description, value, imageUrl } = props;
    
    setFormData({
      ...formData,
      productCode: code ? code : 999,
      productDescription: description,
      productValue: value,
      productImageUrl: imageUrl,
    });

      setPage(page + 1);
  };

  const ProductDetail = ({ product }) => {
    return (
      <>
        <div
          onClick={() => handleClick(product)}
          className="rounded-lg p-0 overflow-hidden grid content-between justify-items-center cursor-pointer hover:shadow-lg hover:z-10 active:shadow"
        >
          <div className="item-thumb">
          {product.imageUrl && (<img src={product.imageUrl} alt={product.description} />)}
          {product.image && (<img src={product.image} alt={product.description} />)}
            
          </div>
          <div className="overflow-hidden w-full">

          { 
            formData.type != 2 && (

            <h5 className="text-center text-sm py-3 text-ellipsis overflow-hidden">
              {product.description}
            </h5>
            )
          }
           
          
            {
              formData.subType == 85 && (
                <div>
                <h5 className="text-center text-sm pb-3 text-ellipsis overflow-hidden">
                  {formData.customerDocumentType}: {product.documento}
                </h5>
                <h5 className="text-center text-sm pb-3 text-ellipsis overflow-hidden">
                  Factura No: {product.code}
                </h5>
                <h5 className="text-center text-sm text-ellipsis overflow-hidden font-semibold">
                  Vence:
                </h5>
                <h5 className="text-center text-sm pb-3 text-ellipsis overflow-hidden font-semibold">
                  {product.vencimiento}
                </h5>
                </div>
              )
            }
          </div>
          {
           product.value ? <h5 className="flex justify-center font-bold">${new Intl.NumberFormat('es-CO').format(product.value)}</h5> : undefined
          }
        </div>
      </>
    );
  };

  return (
    <>
      <p className="text-[#28367B] font-['Roboto', Sans-serif] font-extrabold md:text-2xl tracking-tight leading-snug mb-4 text-left">
        {
          formData.type == 17 ? 'Exequiales' : formData.type == 2 ? 'Operador' : 'Elije una opción'
        }

        {
          formData.subType == 85 || formData.subType == 86 || formData.subType == 87 || formData.subType == 88 ? ' - Los Olivos' : ''
        }

      </p>
      
      {
        isLoading ?
          <div className="flex justify-center">
            <ReactLoading type="spin" color="gray" width={"8%"} className="mr-3" />
            cargando...
          </div> :
        !products || products.length ==0? <p className="flex justify-center text-gray-600">No existen productos para {formData.subTypeDescription}</p> :
        <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-3 overflow-y-scroll max-h-[70vh]">
        {products.map((product) => (
          <ProductDetail key={product.code} product={product} />
        ))}
      </div>
      }
     
    </>
  );
}

export default ProductList;
