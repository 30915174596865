import React, { useState, useEffect } from 'react';
import { getTypesNew } from '../../services/digitalProducts';
import { useNavigate } from 'react-router-dom';

function ProductTypesNew() {

  const navigate = useNavigate()
  const [types, setTypes] = useState([]);

  useEffect(() => {
    getTypes_();
  }, []);

  const getTypes_ = async () => {
    setTypes(await getTypesNew());
  };

  const handleClick = (props) => {
    navigate('/productType/'+props.code)
  };

  const Card = ({ type }) => {
    return (
      <div className="flex flex-col hover:box-shadow-2xl text-[#28367B] px-3 py-2 rounded-md text-sm font-bold rounded-lg items-center overflow-hidden p-3 cursor-pointer">
        <img
          onClick={() => handleClick(type)}
          src={type.imageUrl}
          alt={type.description}
          className='block w-20 lg:w-40 h-auto'
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row flex-wrap justify-center gap-3">

      {types.map((type) =>
          <Card
            key={type.code}
            type={type}
          />
        )}
     
      </div>
    </>
  );

}

export default ProductTypesNew

