import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function HeaderNew() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out bg-[#EC9D13] ${!top && 'bg-[#EC9D13] backdrop-blur-sm shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <a href="https://fullmovil.com.co" className="block" aria-label="Cruip">
              <img
                className="h-20 w-30"
                src="https://fullmovil.com.co/wp-content/uploads/2022/11/Fullmovil_new_Azul-Blanco.svg"
                alt="home"
              />
            </a>
          </div>

          {/* Site navigation */}

          <nav>
  <div class="container">
    <div class="row">
      <div class="mobile_btn">
        <svg class="block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
      </div>
      <div class="main_menu">
        <ul>

          <li id="menu-item-1825" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1825">
            <a href="http://recarga.fullmovil.com.co/autenticacion"><span>Soy Tendero</span></a>
          </li>
          
          
          <li class="mega_menu_dropdown mega_menu_demo_2 has_dropdown">
            <a href="#">Recarga Express <i class="fas fa-angle-down"></i></a>
            <div class="mega_menu sub_menu desktop">
              
              <div class="mega_menu_item">
                <h3>Celular</h3>
                <a href="#">Recargas</a>
                <a href="#">Paquetes</a>
              </div>
              <div class="mega_menu_item">
                <h3>Entretenimiento</h3>
                <a href="#">Netflix</a>
                <a href="#">Amazon Prime</a>
                <a href="#">Spotify</a>
                <a href="#">Paramount+</a>
                <a href="#">Directv</a>
                <a href="#">Directv Go</a>
                <a href="#">Deezer</a>
                <a href="#">Win Sports</a>
                <a href="#">Tinder +</a>
                <a href="#">Tinder Gold</a>
                <a href="#">Crunchyroll</a>
              </div>
              <div class="mega_menu_item">
                <h3>Juegos</h3>
                <a href="#">XBOX</a>
                <a href="#">XBOX Live</a>
                <a href="#">XBOX Game Pass</a>
                <a href="#">IMVU</a>
                <a href="#">PlayStation</a>
                <a href="#">Rixty</a>
                <a href="#">Nogging</a>
                <a href="#">FreeFire</a>
                <a href="#">League of Legends</a>
                <a href="#">Razer Gold</a>
              </div>

              <div class="mega_menu_item">
                <h3>Servicios</h3>
                <a href="#">Office</a>
                <a href="#">Kaspersky</a>
                <a href="#">Datacrédito</a>
              </div>

              <div class="mega_menu_item">
                <h3>Seguros</h3>
                <a href="#">Asistencia Médica</a>
                <a href="#">Asistencia Dental</a>
              </div>

              <div class="mega_menu_item">
                <h3>Exequiales</h3>
                <a href="#">Pago de Facturas</a>
                <a href="#">Plan mi Familia</a>
                <a href="#">Plan muy Familiar</a>
                <a href="#">Plan Repatriación</a>
                <a href="#">Plan Mascotas</a>
              </div>
             
            </div>
            <ul class="sub_menu mobile">
              
              
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="g/"><span>Home Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="ng/"><span>Office Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="/"><span>Deep Cleaning</span></a>
              </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="out-cleaning/"><span>Move In/Out Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="aning/"><span>Apartment Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="cleaning-maids/"><span>Cleaning Services For Seniors</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="tion-cleaning/"><span>Post Construction Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="al-cleaning/"><span>Vacation Rental Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#"><span>Holiday Cleaning</span></a>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#"><span>Maid Cleaning Service</span></a>
              </li>
            </ul>
            </li>
            <li><a href="https://fullmovil.com.co/quienes-somos/">Quienes somos <i class="fas fa-angle-down"></i></a></li>
            <li><a href="https://fullmovil.com.co/haz-parte-de-nuestra-red/">Haz Parte de Nuestra Red <i class="fas fa-angle-down"></i></a></li>
            
            
        </ul>
      </div>
    </div>
  </div>
</nav>

        </div>
      </div>
    </header>
  );
}

export default HeaderNew;
