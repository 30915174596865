import cellphoneRecharge from "../components/icons/cellphoneRecharge.svg"
import cellphonePackages from "../components/icons/cellphonePackages.svg"
import entertaiment from "../components/icons/entertaiment.svg"
import bets from "../components/icons/bets.svg"
import games from "../components/icons/games.svg"
import services from "../components/icons/services.svg"
import insurance from "../components/icons/insurance.svg"
import losolivos from "../components/icons/losolivos.svg"

import cellphoneRechargeNew from "../components/icons/2024/cellphoneRecharge.png"
// import cellphonePackagesNew from "../components/icons/2024/cellphonePackages.png"
import entertainmentNew from "../components/icons/2024/entertainment.png"
import betsNew from "../components/icons/2024/bets.png"
import gamesNew from "../components/icons/2024/games.png"
import servicesNew from "../components/icons/2024/services.png"
import insuranceNew from "../components/icons/2024/insurance.png"
import losolivosNew from "../components/icons/2024/losolivos.png"
import soatNew from "../components/icons/2024/soat.png"

import bgFullmovilNew from "../images/bgfullmovil.png"
import logoFullmovilWhite from "../images/logoFullmovilWhite.png"
import textUnClick from "../images/textUnClic.png"

export const API_HOST = "https://sales.fullmovil.com.co:7002";
export const API_HOST_TEST = "https://sales.fullmovil.com.co:7003";
export const API_WOMPI_HOST_TEST = "https://sandbox.wompi.co/v1";
export const API_WOMPI_HOST = "https://production.wompi.co/v1"

export const API_BEPAY_HOST_TEST = "https://stage.bepay.net.co/api/v1";
export const API_BEPAY_HOST = "https://stage.bepay.net.co/api/v1"

export const PAYMENTMANAGER_API_HOST="https://fullseguros.com.co:7004";

export const PUBLIC_KEY_TEST = 'pub_test_zjiuCcxRneRoJqg16qBqVZXJwxXBE6sG'

// export const PUBLIC_KEY = 'pub_prod_93cRVp18YlLXhGGnuy81u5en9SRPVzu1';

export const PUBLIC_KEY = 'pub_prod_fpcgnt3ZStyk39y1eDpqqUgnq6cFOpSk';


export const PRODUCTION_ENV_URL = "https://sales.fullmovil.com.co";

export const TEST_ENV_URL = "https://sales.fullmovil.com.co:4443";


//background image
export const BACKGROUND_IMAGE = "https://fullmovil.com.co/wp-content/uploads/2021/11/Full-movil-banner-home-3.png"

//New background image
export const BACKGROUND_IMAGE_NEW = bgFullmovilNew;

export const LOGO_FULLMOVIL_WHITE = logoFullmovilWhite;
export const TEXT_UN_CLICK = textUnClick;

//cover images
export const COVER_RECHARGE = 'https://fullmovil.com.co/wp-content/uploads/2022/10/Recargas_celular_1024x1024.png';
export const COVER_ENTERTAINMENT = '	https://fullmovil.com.co/wp-content/uploads/2022/10/Recarga_entretenimiento_1024x1024.png';
export const COVER_GAMES = 'https://fullmovil.com.co/wp-content/uploads/2022/10/Recargas_juegos1024x1024.png';
export const COVER_SERVICES = 'https://fullmovil.com.co/wp-content/uploads/2022/10/Recargs_licencias_1024x1024.png';
export const COVER_INSURANCE = 'https://fullmovil.com.co/wp-content/uploads/2022/12/Seguros-Fullmovil-recargas.png';
export const COVER_BETS = 'https://fullmovil.com.co/wp-content/uploads/2022/10/Recargas_apuestas_1024x1024.png';
export const COVER_LOSOLIVOS = 'https://fullmovil.com.co/wp-content/uploads/2023/11/exequiales.png';

//Icons
export const CELLPHONE_ICON = cellphoneRecharge;
export const PACKAGES_ICON = cellphonePackages;
export const ENTERTAIMENT_ICON = entertaiment;
export const BETS_ICON = bets;
export const GAMES_ICON = games;
export const SERVICES_ICON = services;
export const INSURANCE_ICON = insurance;
export const LOSOLIVOS_ICON = losolivos;

export const CELLPHONE_ICON_NEW = cellphoneRechargeNew;
// export const PACKAGES_ICON_NEW = cellphonePackagesNew;
export const ENTERTAINMENT_ICON_NEW = entertainmentNew;
export const BETS_ICON_NEW = betsNew;
export const GAMES_ICON_NEW = gamesNew;
export const SERVICES_ICON_NEW = servicesNew;
export const INSURANCE_ICON_NEW = insuranceNew;
export const LOSOLIVOS_ICON_NEW = losolivosNew;
export const SOAT_ICON_NEW = soatNew;

export const PROD_MODE = "TRUE";
