import React, { useState, useEffect } from "react";
import { getProducts, getOperatorInfo } from "../../services/digitalProducts";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";

function ProductListSeguros({ page, setPage, formData, setFormData }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getproducts = async (type, subType) => {

    let products = [];

    if (type == 10) {
      products = await getOperatorInfo(subType);
    } else {
      products = await getProducts(type, subType);
    }

    setIsLoading(false);
    setProducts(products);

    // console.log('Products: ', products);
  };

  useEffect(() => {
    getproducts(formData.type, formData.subType);
  }, []);

  const handleClick = (props) => {
    const { code, description, value, imageUrl } = props;
    
    setFormData({
      ...formData,
      productCode: code,
      productDescription: description,
      productValue: value,
      productImageUrl: imageUrl,
    });

      setPage(page + 1);
    
  };

  const ProductDetail = ({ product }) => {
   
    return (
      <>
        <div className="rounded-lg p-3 shadow-lg overflow-hidden grid content-between justify-items-center hover:shadow-xl hover:z-10 active:shadow">
          
          <div className="overflow-hidden w-full">
            <h2 className="text-center py-3 text-lg overflow-hidden font-bold">
              {product.description}
            </h2>
            <div className="bg-[#EC9D13] px-2 py-1 text-[#28367B] text-2xl text-center font-bold">¿Qué te cubre?</div>

              {
                  Object.values(product.info).map((item, index) => (
                    <div key={index}>
                      <div className="bg-white px-2 py-2 font-semibold">
                          {(typeof item) == 'string' ?  item.replaceAll(/<\/?[^>]+(>|$)/gi, "") : Object.keys(item)}
                      </div>

                      <ul className="pl-6 list-disc">
                        {
                          typeof item == 'object' && (Object.values(item).map(subitem => (
                          subitem.map(service => (
                            <li key={service}>{service}</li>
                          ))
                        )))}
                      </ul>
                    </div>
                  ))
              }

            <div className="bg-white px-2 py-2 font-semibold text-red-700">
              Consulta términos y condiciones del seguro <a href={product.term_and_cond} rel="noreferrer" className="text-blue-700" target="_blank">aquí</a>
            </div>
            
            
          </div>
              {product.value ? <h5 className="flex justify-center font-bold">${new Intl.NumberFormat('es-CO').format(product.value)}</h5> : undefined}

              <div onClick={() => handleClick(product)} className="bg-[#EC9D13] w-1/2 md:w-1/3 px-2 py-1 border-2 rounded-md text-lg text-[#28367B] text-center cursor-pointer mt-5">
                Continuar
              </div>
        </div>
            
      </>
    );
  };

  return (
    <>
      <p className="text-[#28367B] font-['Roboto', Sans-serif] font-extrabold md:text-2xl tracking-tight leading-snug mb-4 text-left">
        Producto
      </p>
      
      {
        isLoading ?
          <div className="flex justify-center">
            <ReactLoading type="spin" color="gray" width={"8%"} className="mr-3" />
            cargando...
          </div> :
        !products || products.length ==0? <p className="flex justify-center text-gray-600">No existen productos para {formData.subTypeDescription}</p> :
        <div className="w-full overflow-y-scroll max-h-[70vh]">
        {products.map((product) => (
          <ProductDetail key={product.code} product={product} />
        ))}
      </div>
      }
     
    </>
  );
}

export default ProductListSeguros;
