import moment from "moment/moment";

const characters ='0123456789';

  function generateString(length) {
      let result = ' ';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
  }

export const generatePaymentReference = () => {
  // return "pay_wt_62691" + generateString(5).trim() + "-" + moment().unix();
  return "pay_wt_62691-" + moment().unix();
}

export const generateDpoReference = () => {
  // return "dpo_wt_62691" + generateString(5).trim() + "-" + moment().unix();
  return "dpo_wt_62691-" + moment().unix();
}