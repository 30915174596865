import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReactLoading from "react-loading";
import cookie from "js-cookie";
import * as Yup from "yup";

import { Modal } from "../Modal";
import { createOrder } from "../../../services/digitalProducts";
import bancolombiaIcon from "../../../images/bancolombia_button.png"
import { PRODUCTION_ENV_URL, PROD_MODE, TEST_ENV_URL } from "../../../lib/envariables";

export const FormBancolombiaTransfer = ({ formData, setFormData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const redirectUrl = PROD_MODE.toUpperCase() == "TRUE" ? PRODUCTION_ENV_URL : TEST_ENV_URL;

  const createBancolombiaTransferOrder = async(values) => {
    const requestData = {
        type: formData.type,
        subType: formData.subType,
        productId: formData.productCode ? formData.productCode : 999,
        reference: formData.customerCellphone,
        value: formData.productValue,
        paymentMethod: 3,
        paymentToken: "29568",
        productData: {},
        redirectUrl: redirectUrl + '/#/payment/summary/',
        paymentData: {
          docType:values.documentType,
          docNumber: formData.customerDocument ? formData.customerDocument : values.documentNumber,
          customerMail: values.customerMail ? formData.customerMail : 'johndoe@gmail.com',
          name: values.name ? values.name : 'John Doe',
          nequiPhone: formData.nequiPhone ? formData.nequiPhone : '3991111111',
          numberPlate: formData.numberPlate ? formData.numberPlate : 'XXX000',
          registrationId: formData.registrationId ? formData.registrationId : '000000',
          city: formData.city ? formData.city : '00',
          contractNumber: formData.contractNumber ? formData.contractNumber : '00',
          payerPhone: values.payerPhone ? values.payerPhone : '3991111111',
          payerDocType:values.documentType,
          payerDocNumber: values.documentNumber ? values.documentNumber : '9999999',
          payerCustomerMail: values.customerMail ? formData.customerMail : 'johndoe@gmail.com',
          payerName: values.name ? values.name : 'John Doe',
      }
    }
    setIsLoading(true);
    const resp = await createOrder(requestData);
    setIsLoading(false);

    if(resp.success) {
      cookie.set('order', JSON.stringify({
        ...resp,
          subTypeDescription: formData.subTypeDescription,
          productDescription: formData.productDescription,
          customerCellphone: formData.customerCellphone,
          customerMail: formData.customerMail,
          customerDocument: formData.customerDocument,
          paymentMethodDescription: formData.paymentMethodDescription,
          payerPhone: formData.payerPhone
      }), {
        path: "/"
      });
      window.location.replace(resp.data.RedirectUrl)
    } else{
      setAlertMessage(resp.message);
      setStateModal(true);
    }
  }
 

  const handleKeyPress = (e) => {
    var code = e.which ? e.which : e.keyCode;
    if (code != 13) {
      if (e.target.value.length >= 10) {
        e.preventDefault(e.validate);
      }
      if (code < 48 || code > 57) {
        e.preventDefault(e.validate);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex z-10 justify-center items-center transition">
          <div className="bg-white opacity-30 fixed inset-0 z-20" />
          <ReactLoading
            type="spin"
            color="black"
            width={"50px"}
            className="z-30"
          />
        </div>
      )}
      {stateModal && <Modal text={alertMessage} setStateModal={setStateModal} />}
      <Formik
        initialValues={{
          documentType: "",
          documentNumber: formData.CustomerDocument,
          customerMail: formData.CustomerMail,
          name: formData.name,
          payerPhone: formData.payerPhone

        }}
        validationSchema={Yup.object({
          documentType: Yup.string().required("Campo obligatorio"),
          documentNumber: Yup.number()
            .max(9999999999, "Se deben ingresar 10 caracteres")
            .required("Campo obligatorio"),
          customerMail: Yup.string().email('Debe ser un correo válido').required("Campo obligatorio"),
          name: Yup.string().matches(/^[A-Za-z ]*$/, 'Debe ser un nombre válido').min(5, 'Mínimo 5 caracteres').required("Campo obligatorio"),
          payerPhone: Yup.number()
            .max(9999999999, "Se deben ingresar 10 caracteres").required("Campo obligatorio"),
        })}
        onSubmit={async (values) => {
          createBancolombiaTransferOrder(values);
        }}
      >
        <Form className="w-full max-w-lg bg-white shadow-md rounded px-8 py-6 mb-4 mt-2">
          <div className="flex flex-row justify-center align-middle mb-4">
            <img src={bancolombiaIcon} alt="Bancolombia" className="w-8 h-8 mr-2" />
            <h1 className="text-[#001174] text-2xl">Transferencia Bancolombia</h1>
          </div>
          
          <div className="block text-gray-800 font-bold mb-6 text-xl">
            Total a pagar: ${new Intl.NumberFormat('es-CO').format(formData.productValue)}
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="documentType"
          >
            Tipo de documento
          </label>
          <Field
            className="bg-[#F6F6F6] shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="documentType"
            as="select"
          >
            <option value="0">Selecciona un tipo de documento</option>
            <option value="CC">Cédula de ciudadanía</option>
            <option value="NIT">Identificación tributaria</option>
            <option value="CE">Cédula de extranjería</option>
          </Field>
          <div className="text-red-600">
            <ErrorMessage name="documentType" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="documentNumber"
          >
            Número de documento
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="documentNumber"
            type="tel"
            onKeyPress={handleKeyPress}
          />
          <div className="text-red-600">
            <ErrorMessage name="documentNumber" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="name"
          >
            Nombre Completo
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="name"
            type="text"
          />
          <div className="text-red-600">
            <ErrorMessage name="name" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="customerMail"
          >
            Correo Electrónico
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="customerMail"
            type="text"
          />
          <div className="text-red-600">
            <ErrorMessage name="customerMail" />
          </div>

          <label
            className="block text-gray-700 text-sm font-bold mt-4"
            htmlFor="payerPhone"
          >
            Ingrese un número telefónico para recibir confirmación de su pago vía SMS.
          </label>
          <Field
            className="bg-[#F6F6F6] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="payerPhone"
            type="tel"
          />
          <div className="text-red-600">
            <ErrorMessage name="payerPhone" />
          </div>

          <div className="text-sm my-4 text-center">Al presionar PAGAR estás aceptando los <a href="https://fullmovil.com.co/terminos-y-condiciones/" target="_blank" rel="noreferrer" className=" text-blue-500">Términos y Condiciones</a></div>

          <button
            className="bg-lime-600 customFont text-white mt-4 w-full py-2 px-3"
            type="submit"
          >
            {isLoading ? <>PROCESANDO...</> : <>PAGAR</>}
          </button>

        
        </Form>
      </Formik>
    </>
  );
};
